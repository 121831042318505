import { Asset } from 'types/asset';

import { FinancialAsset } from './financial-asset';
import { GiftAsset } from './gift-asset';
import { OtherAsset } from './other-asset';
import { PropertyAsset } from './property-asset';
import { VehicleAsset } from './vehicle-asset';

type Props = {
    isEditing: boolean;
    applicationId: number;
    applicantId?: number;
    asset: Asset;
    typeWatch: Asset['type'];
};

export const AssetType = ({
    isEditing,
    applicationId,
    applicantId,
    asset,
    typeWatch,
}: Props) => {
    const ASSET = {
        GIFT: GiftAsset,
        PROPERTY: PropertyAsset,
        VEHICLE: VehicleAsset,
        OTHER: OtherAsset,
        SAVINGS: FinancialAsset,
        TFSA: FinancialAsset,
        RRSP: FinancialAsset,
        NON_REGISTERED_INVESTMENTS: FinancialAsset,
        HELOC: FinancialAsset,
    };

    const AssetTypeComponent = ASSET[typeWatch];

    if (!typeWatch || !AssetTypeComponent) return null;

    return (
        <AssetTypeComponent
            isEditing={isEditing}
            applicationId={applicationId}
            applicantId={applicantId}
            asset={asset}
        />
    );
};
