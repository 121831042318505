import { useTenantSettings } from '@nestoca/multi-tenant';
import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { ASSET_TYPE_OPTIONS } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { getApplicantsSelectOptions } from 'store/applications';

import styles from './asset.module.scss';

type Props = {
    isEditing: boolean;
    applicationId: number;
    applicantId?: number;
};

export const AssetCreationFields = ({
    isEditing,
    applicationId,
    applicantId,
}: Props) => {
    const { i18n } = useI18n();
    const {
        typesOverride: {
            settings: { assetTypes },
        },
    } = useTenantSettings();

    // Get applicants names / id option values
    const applicantOptions = useRecoilValue(
        getApplicantsSelectOptions(applicationId)
    );

    const assetTypeOptions = assetTypes.map((type) => {
        return ASSET_TYPE_OPTIONS(i18n)[type];
    });

    return (
        <>
            <EditableCell
                className={styles['applicant-field']}
                required
                isEditing={isEditing}
                fieldType="select"
                name="applicantId"
                label="applicant"
                options={applicantOptions}
                value={applicantId}
            />
            <EditableCell
                className={styles['type-field']}
                required
                isEditing={isEditing}
                name="type"
                label="type"
                fieldType="select"
                options={assetTypeOptions}
                value={''}
            />
        </>
    );
};
