import React, { useState, useEffect, PropsWithChildren } from 'react';

import { Box, Checkbox } from '@nestoca/ui';
import cn from 'classnames';

import { Divider } from 'components/divider/divider';

import styles from './check-list.module.scss';

export const CheckList = ({ children }: PropsWithChildren) => (
    <ul className={styles['check-list']}>{children}</ul>
);

type CheckListItemProps = {
    children: React.ReactNode;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    hideCheckbox?: boolean;
    checked?: boolean;
};

export const CheckListItem = ({
    children,
    onChange,
    disabled = false,
    hideCheckbox = false,
    ...rest
}: CheckListItemProps) => {
    const [checked, setChecked] = useState(rest?.checked);

    useEffect(() => {
        if (typeof rest?.checked) {
            setChecked(rest.checked);
        }
    }, [rest?.checked]);

    return (
        <li>
            <Checkbox
                disabled={disabled}
                checked={checked}
                className={cn({ [styles['checkbox--hidden']]: hideCheckbox })}
                onChange={(event) => {
                    onChange?.(event);
                }}
            >
                <Box
                    className={cn(styles['checkbox-label'], {
                        [styles['checkbox-label--checked']]: checked,
                    })}
                >
                    {children}
                </Box>
            </Checkbox>
            <Divider />
        </li>
    );
};
